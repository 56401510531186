











import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductCardBadge',
  props: {
    badgeClass: {
      type: String,
      required: true,
    },
    badgeTranslationKey: {
      type: String,
      required: true,
    },
  },
});
