











































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import type { PropType } from '@nuxtjs/composition-api';
import type { ProductPricingSize } from '~/constants/productCardPricing';
import { useI18n } from '~/composables/useI18n';
import StrikethroughPrice from '~/components/atoms/Product/StrikethroughPrice.vue';
import { useRouteExtended } from '~/composables';
import { ROUTES } from '~/constants/routes';
import { SCHEMA_ITEM_PROP } from '~/constants/seo';
import { PRODUCT_PRICING_SIZE } from '~/constants/productCardPricing';

export default defineComponent({
  name: 'ProductPricing',
  components: {
    StrikethroughPrice,
  },
  props: {
    price: {
      type: Number,
      required: true,
    },
    priceBeforeDiscounts: {
      type: Number,
      default: null,
    },
    shouldDisplayDiscount: {
      type: Boolean,
      default: true,
    },
    qty: {
      type: Number,
      default: 1,
    },
    showTotalPrice: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      required: false,
    },
    isActiveProduct: {
      type: Boolean,
      default: false,
    },
    isVerticalPrice: {
      type: Boolean,
      default: false,
    },
    isPriceCentered: {
      type: Boolean,
      default: true,
    },
    reversePrices: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<ProductPricingSize>,
      default: PRODUCT_PRICING_SIZE.DEFAULT,
      validator: (value: ProductPricingSize) => {
        return Object.values(PRODUCT_PRICING_SIZE).includes(value);
      },
    },
  },
  setup(props, { root: { $route } }) {
    const { countryLocalization } = useI18n();
    const { getAdjustedSlug } = useRouteExtended();
    const { i18n } = useVSFContext();
    const priceCurrency = computed(() => countryLocalization.value.currencyCode);

    const currentPrice = computed(() => {
      if (props.priceBeforeDiscounts !== null) {
        // on ThankYou page, total price of each item should be displayed
        return props.showTotalPrice ? props.priceBeforeDiscounts * props.qty : props.priceBeforeDiscounts;
      } else {
        return props.showTotalPrice ? props.price * props.qty : props.price;
      }
    });
    const oldPrice = computed(() => (props.priceBeforeDiscounts !== null ? props.price : null));
    const isCart = computed(() => $route.path === getAdjustedSlug(ROUTES.CART));
    const isDiscountDisplayed = computed(() => oldPrice.value !== null && props.shouldDisplayDiscount);

    const isStrikethrouhgPriceHiddenOnMobile = computed(() => isCart.value && !props.readOnly);

    const primaryPrice = computed(() => (props.reversePrices && isDiscountDisplayed.value)
      ? i18n.n(oldPrice.value, 'currency')
      : i18n.n(currentPrice.value, 'currency'));
    const secondaryPrice = computed(() => props.reversePrices
      ? i18n.n(currentPrice.value, 'currency')
      : i18n.n(oldPrice.value, 'currency'));

    const primaryPriceSizes = {
      [PRODUCT_PRICING_SIZE.SMALL]: 'leading-6 text-sm',
      [PRODUCT_PRICING_SIZE.MEDIUM]: 'text-2xl',
      [PRODUCT_PRICING_SIZE.DEFAULT]: 'text-3xl',
    };
    const primaryPriceSize = computed(() => primaryPriceSizes[props.size]);

    return {
      currentPrice,
      oldPrice,
      isCart,
      SCHEMA_ITEM_PROP,
      priceCurrency,
      isDiscountDisplayed,
      primaryPrice,
      secondaryPrice,
      PRODUCT_PRICING_SIZE,
      primaryPriceSize,
      isStrikethrouhgPriceHiddenOnMobile,
    };
  },
});
