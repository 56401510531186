






















import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import ProductPricing from '~/components/molecules/Product/ProductPricing/ProductPricing.vue';
import PriceDisclaimer from '~/components/molecules/Product/PriceDisclaimer.vue';
import { usePriceDisclaimer } from '~/composables';
import { PRODUCT_PRICING_SIZE, ProductPricingSize } from '~/constants/productCardPricing';

export default defineComponent({
  name: 'ProductCardPricing',
  components: {
    LazyHydrate,
    ProductPricing,
    PriceDisclaimer,
  },
  props: {
    price: {
      type: Number,
      required: true,
    },
    discountedPrice: {
      type: Number,
      default: null,
    },
    size: {
      type: String as PropType<ProductPricingSize>,
      default: PRODUCT_PRICING_SIZE.DEFAULT,
      validator: (value: ProductPricingSize) => {
        return Object.values(PRODUCT_PRICING_SIZE).includes(value);
      },
    },
  },
  setup(props) {
    const { isPriceDisclaimerEnabled } = usePriceDisclaimer();

    const standardPrice = computed(() => props.discountedPrice !== null && props.price !== null ? props.price : null);

    return {
      standardPrice,
      isPriceDisclaimerEnabled,
    };
  },
});
