import { ProductRating } from '~/types/product/attribute/ProductReview';

export default ({
  averageRating,
  count,
  defaultTo0,
}: {
  averageRating?: Maybe<number>,
  count?: Maybe<number>,
  defaultTo0?: Maybe<boolean>
}): ProductRating => {
  return {
    averageRating: defaultTo0 ? averageRating || 0 : averageRating,
    count: defaultTo0 ? count || 0 : count,
  };
};
