











import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { ProductTag, productTag } from '~/types/product/attribute/ProductTag';
import ProductCardBadge from '~/components/atoms/Product/ProductCardBadge.vue';
import type { ProductBadge } from '~/types/product/ProductBadge';
import { MAX_AMOUNT_OF_BADGES } from '~/constants/numbers';

export default defineComponent({
  name: 'ProductCardBadges',
  components: {
    ProductCardBadge,
  },
  props: {
    tags: {
      type: Array as PropType<ProductTag[]>,
      required: true,
    },
  },
  setup (props) {
    const tagToClassMap = {
      [productTag.New]: 'product-badge--new',
      [productTag.Popular]: 'product-badge--popular',
      [productTag.Sale]: 'product-badge--sale',
      [productTag.Bware]: 'product-badge--bware',
      [productTag.TopRated]: 'product-badge--top-rated',
      [productTag.BlackFriday]: 'product-badge--black-friday',
      [productTag.CyberWeek]: 'product-badge--cyber-week',
    };
    // TODO - decide whether we want to switch to 'translations accessed directly in setup' or keep it like this.
    const tagToTextMap = {
      [productTag.New]: 'New Product',
      [productTag.Popular]: 'Popular Product',
      [productTag.Sale]: 'Product On Sale',
      [productTag.Bware]: 'B-Goods Product',
      [productTag.TopRated]: 'Top Rated Product',
      [productTag.BlackFriday]: 'Black Friday',
      [productTag.CyberWeek]: 'Cyber Week',
    };

    const priorityOrder: ProductTag[] = [
      productTag.Sale,
      productTag.CyberWeek,
      productTag.BlackFriday,
      productTag.Bware,
      productTag.Popular,
      productTag.New,
      productTag.TopRated,
    ];

    const getBadge = (productTag: ProductTag): ProductBadge | undefined => {
      if (!productTag) {
        return;
      }

      const badgeClass = tagToClassMap[productTag];
      const badgeTranslationKey = tagToTextMap[productTag];

      if (badgeClass && badgeTranslationKey) {
        return {
          badgeClass,
          badgeTranslationKey,
        };
      }
    };

    const sortTagsByPriority = (tags: ProductTag[]): ProductTag[] => {
      const excludeCustomTags = tags.filter(tag => priorityOrder.includes(tag));
      return excludeCustomTags.sort((tagA, tagB) => {
        const priorityA = priorityOrder.indexOf(tagA);
        const priorityB = priorityOrder.indexOf(tagB);

        return priorityA - priorityB;
      });
    };

    const sortedTags = computed(() => {
      if (props.tags.length < 2) {
        return props.tags;
      }

      const tags = props.tags.slice();
      return sortTagsByPriority(tags);
    });

    const badges = computed(() => {
      const badgeArray = [];

      for (const tag of sortedTags.value) {
        const badge = getBadge(tag);
        if (badge) {
          badgeArray.push(badge);
        }

        if (badgeArray.length === MAX_AMOUNT_OF_BADGES) {
          break;
        }
      }

      return badgeArray;
    });

    return {
      badges,
    };
  },
});
