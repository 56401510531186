















import { computed, defineComponent } from '@nuxtjs/composition-api';
import { usePriceDisclaimer } from '~/composables';

export default defineComponent({
  name: 'PriceDisclaimer',
  props: {
    standardPrice: {
      type: Number,
      default: null,
    },
    fontSizeSmall: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const { isPriceDisclaimerEnabled } = usePriceDisclaimer();
    const showDisclaimer = computed(() => isPriceDisclaimerEnabled.value && props.standardPrice !== null);

    return {
      showDisclaimer,
    };
  },
});
